import Link from 'next/link';
import { Theme } from '@/contexts/ThemeContext';

const services = (theme?: Theme) => [
  {
    url: '/renewal-old',
    title: `Renew your ${
      theme.slug !== 'eyesol' && 'driver’s'
    } licence if you're 70 or over`,
    subtitle: `You must renew your ${
      theme.slug !== 'eyesol' && 'driving'
    } licence if you’re 70 or over – or will be 70 in the next 90 days.`,
  },
  {
    url: '/renewal',
    title: `Renew your ${
      theme.slug !== 'eyesol' && 'driver’s'
    } licence (10 year replacement)`,
    subtitle: 'You must renew a photocard licence every 10 years.',
  },
  {
    url: '/address-change',
    title: `Change of address on your ${
      theme.slug !== 'eyesol' && 'driver’s'
    } licence`,
    subtitle: 'You must update your provisional or full licence when you move.',
  },
  {
    url: '/replacement',
    title: 'Replace a lost, stolen, damaged or destroyed licence',
    subtitle: 'Get a new licence if your’s is lost, stolen or damaged.',
  },
  {
    url: '/provisional',
    title: 'Apply for your first provisional licence',
    subtitle: `Get your first provisional ${
      theme.slug !== 'eyesol' && 'driver’s'
    } licence.`,
  },
];

const Section = ({
  url,
  title,
  subtitle,
}: {
  url: string;
  title: string;
  subtitle: string;
}): JSX.Element => {
  return (
    <div className="">
      <span className="block md:flex items-center text-base font-bold text-gray-900">
        {title}
      </span>
      <span
        style={{ fontSize: '0.9rem' }}
        className="block md:flex items-center font-base text-gray-900"
      >
        {subtitle}
      </span>
      <div className="mt-2">
        <Link href={url}>
          <a className="inline-block px-8 py-3 text-base leading-6 font-bold text-white bg-primary-dark cursor-pointer rounded hover:bg-primary hover:border-primary focus:outline-none focus:bg-white focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-2 md:px-8">
            {'Apply Now >'}
          </a>
        </Link>
      </div>
    </div>
  );
};

export default function Selector({ theme }: { theme: Theme }): JSX.Element {
  return (
    <div className="container lg:pr-8 flex flex-col mx-auto py-8 px-6 sm:px-32">
      <h2 className="text-2xl font-bold text-center sm:text-left font-display text-primary mb-3">
        Select type of service you require
      </h2>
      <div className="border border-gray px-6 lg:pl-20 py-2 bg-white table text-center md:text-left">
        {services(theme).map(({ url, title, subtitle }, index) => {
          return (
            <div className="my-4" key={index}>
              <Section url={url} title={title} subtitle={subtitle} />
            </div>
          );
        })}
      </div>
      <p className="mt-4">
        If you are already a customer, you can connect to your{' '}
        <strong>member zone</strong> using your login information.
      </p>
    </div>
  );
}
